<script setup>
  import loadingImage from '/public/images/lottie_files/finger-heart.json';
  import { useUsersStore } from '@/stores/users';
  import { useI18n } from 'vue-i18n'
  const { locale } = useI18n()

  async function hideLoading() {
    isLoading.value = true;
    setTimeout(() => {
      // nothing
    }, 1000); 
    try {
        preloader.value.classList.add('hidden');
      } catch (e) {
        // nothing
      }
    setTimeout(() => {
      isLoading.value = false;
    }, 500); 
  }
  
  const nuxtApp = useNuxtApp();
  const isLoading = ref(true);
  const preloader = ref(null);

  nuxtApp.hook("page:finish", async () => {
    hideLoading();
  });
  nuxtApp.hook("page:loading:end", async () => {
    hideLoading();
  });

  nuxtApp.hook("page:loading:start", async () => {
    isLoading.value = true;
    try {
      preloader.value.classList.remove('hidden');
    } catch (e) {
      // nothing
    }
  });

  onMounted(() => {
    const savedLanguage = useUsersStore().language
    if (savedLanguage) {
      locale.value = savedLanguage // Si existe un idioma guardado en localStorage, lo establece como idioma actual
    }
  })
</script>
<style lang="css">
.hidden {
  opacity: 0 !important;
  pointer-events: none;
}
</style>
<template>
  
  <div v-if="isLoading" ref="preloader" class="fixed top-0 left-0 right-0 bottom-0 d-flex flex-column text-center h-screen align-center justify-center bg-white"
  style="position: absolute; z-index: 9999; opacity: 1; transition: opacity 0.5s ease; background-color: rgba(var(--v-theme-containerBg), 0.7) !important; backdrop-filter: blur(8px) !important; "
  >
    <div class="bg-grey100 rounded rounded-circle pa-1" style="width: 200px; height: 200px;">
      <Vue3Lottie :animationData="loadingImage" width="100%" class="d-block" />
      <h4 class="text-h4 font-weight-normal pt-8">{{ $t('basic.pleaseWait')}}</h4>
    </div>
  </div>
  
  <NuxtLayout >
    <NuxtLoadingIndicator color="primary" :height="4" />
    <NuxtPage />
  </NuxtLayout>
  
</template>
