import { defineRule, configure } from "vee-validate";
import * as Rules from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import es from "@vee-validate/i18n/dist/locale/es.json";
import en from "@vee-validate/i18n/dist/locale/en.json";
import messages from "@/utils/locales/messages";

export default defineNuxtPlugin(() => {
  try {
    for (const [ruleName, rule] of Object.entries(Rules)) {
      if (ruleName && rule && ruleName !== "all") {
        defineRule(ruleName, rule);
      }
    }
  } catch (e) {
    console.error(e);
  }

  defineRule("strongPassword", (value) => {
    if (!value || !value.length) {
      return true;
    }
    if (!/^(?=.[0-9])(?=.[!@#$%^&])[a-zA-Z0-9!@#$%^&]{8,22}$/.test(value)) {
      return i18n.t('vee-validate-errors.strongPassword');
    }
    return true;
  });
  defineRule("onlyText", (value) => {
    return /^[a-zA-Z\u00C0-\u00FF\s]+$/u.test(value) || i18n.t('vee-validate-errors.onlyText');
  });
  defineRule("minAgeBirth", (value) => {
    const inputDate = new Date(value);
    const today = new Date();
    const minDate = new Date(
      today.getFullYear() - 12,
      today.getMonth(),
      today.getDate()
    );

    return inputDate <= minDate || i18n.t('vee-validate-errors.minAgeBirth');
  });
  defineRule("address", (value) => {
    if (value) {
      const regex = /^[a-zA-Z0-9\s,.-]+$/; // Validar caracteres permitidos
      const hasMinLetters = (value.match(/[a-zA-Z]/g) || []).length >= 3; // Al menos 3 letras
      if (!regex.test(value)) {
        return i18n.t('vee-validate-errors.address-invalid');
      }
      if (!hasMinLetters) {
        return i18n.t('vee-validate-errors.address-minlength');
      }
      return true;
    }
    return true;
  });
  defineRule("matches", (value, [otherValue]) => {
    return value == otherValue || i18n.t('vee-validate-errors.matches');
  });
  defineRule('noSpaces', value => {
    if (value && value.includes(' ')) {
      return i18n.t('vee-validate-errors.no-spaces');
    }
    return true;
  });
});

localize({ en, es });

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true,
  generateMessage: localize({
    es,
    en,
  }),
  generateMessage: localize(
    { es: { names: messages.es.fields } },
    { en: { names: messages.en.fields } }
  ),
});

setLocale("es");
